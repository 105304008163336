import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import selectedPost from "../../data/post_details.json";
import { ApiAddress } from "../../data/ApiAddress";
// import NewPost from "./NewPost";
import PostChain from "../devicedetails/PostChain";
import ProjectNewPost from "./ProjectNewPost";

import { useNavigate } from "react-router-dom";

import {
  faPlus,
  faUser,
  faCalendarAlt,
  faComments,
  faComment,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import ProjectPostChain from "./ProjectPostChain";

const ProjectCommunityComponent = ({ community, userName, projectName }) => {
  //   console.log(community);
  // For navigation purpose
  const navigate = useNavigate();

  const [currentPosts, setCurrentPosts] = useState(community);
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedPost, setSelectedPost] = useState(null);
  const [showInidividualPost, setShowIndividualPost] = useState(false);
  const [showNewPost, setShowNewPost] = useState(false);
  const [showAIButton, setShowAIButton] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const sortedCommunityByReplies = [...currentPosts].sort(
    (a, b) => b.replies - a.replies
  );

  const handleNewPostClick = () => {
    // console.log("New Post clicked!");
    setShowIndividualPost(false);
    setShowNewPost(true);
  };

  const handleAIClick = () => {
    // console.log("Ask AI!");
    setShowConfirmDialog(true);
  };

  const handlePostClick = (id) => {
    fetch(ApiAddress + "project_post_detail/", {
      method: "POST",
      body: JSON.stringify({ postId: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedPost(data);
        setShowIndividualPost(true);
        // console.log(deviceDetail);
      })
      .catch((error) => console.error("Error fetching post:", error));
  };

  //   Render Community Posts
  const renderCommunityPosts = (posts) => {
    return posts.map((item, index) => (
      // Post card
      <div
        key={index}
        className="flex items-center bg-dark-primary rounded p-4 mb-4 border border-dark hover:bg-dark-secondary cursor-pointer"
        onClick={() => handlePostClick(item.id)}
      >
        {/* Comment Icon */}
        <div className="w-1/6 flex justify-center items-start">
          <FontAwesomeIcon
            icon={faComments}
            className="text-emerald-500 text-2xl"
          />
        </div>

        {/* Comment Details */}
        <div className="w-3/4 pl-4">
          <h2 className="font-medium mb-2">{item.title}</h2>
          <div className="flex space-x-4 text-xs">
            <div className="flex items-center space-x-2 text-dark-secondary">
              <FontAwesomeIcon icon={faUser} />
              <span>{item.posted_by}</span>
            </div>
            <div className="flex items-center space-x-2 text-dark-secondary">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>{item.posted_on}</span>
            </div>
            <div className="flex items-center space-x-2 text-dark-secondary">
              <FontAwesomeIcon icon={faComment} />
              <span>{item.replies}</span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  // Render detailed Post
  const handleTabClick = (tab) => {
    // console.log(tab);
    setSelectedTab(tab);
    setShowIndividualPost(false); // Resetting so we go back to all posts
    setShowNewPost(false); // Resetting so we go back to all posts
  };

  return (
    <>
      {" "}
      {showConfirmDialog && (
        <div className="fixed inset-0 z-100 flex items-center justify-center">
          {/* Backdrop */}
          <div className="absolute inset-0 bg-black opacity-80 backdrop-blur-md"></div>

          {/* Dialog */}
          <div className="relative bg-dark-primary border border-dark p-4 rounded shadow-lg z-20">
            <p className="text-light-primary pb-2 font-semibold">
              To use AI to synthesize information across multiple devices,
              please sign up for the enterprise version.
            </p>
            <p className="text-sm text-dark-secondary pb-2 font-light">
              contact: prash@ctrlease.com
            </p>
            <div className="flex justify-end mt-2">
              <button
                className="text-sm flex items-center gap-2 mr-4 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
                onClick={() => setShowConfirmDialog(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="h-full text-sm text-light-primary pr-2 mt-20 border-l border-dark pl-5">
        <div className="flex justify-between items-center mb-4">
          {/* Tabs - All and Popular */}
          <div className="flex space-x-4">
            {["All", "Popular"].map((tab) => (
              <button
                key={tab}
                onClick={() => {
                  handleTabClick(tab);
                }}
                className={`py-2 px-4 ${
                  selectedTab === tab
                    ? "text-light-primary border-b-2 border-emerald-500"
                    : "text-dark-secondary border-b-2 border-transparent"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Post Button */}
          <div className="flex flex-row space-x-4">
            {/* {!showAIButton && (
              <button
                className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
                onClick={handleAIClick}
              >
                <FontAwesomeIcon icon={faRobot} />
                <span>Ask AI</span>
              </button>
            )} */}

            {/* Post Button */}
            {!showNewPost && (
              <button
                className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                onClick={handleNewPostClick}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>New Post</span>
              </button>
            )}
          </div>
        </div>
        {/* Render Selected Post if not none */}
        {showInidividualPost ? (
          // <PostChain post={selectedPost} />
          <ProjectPostChain post={selectedPost} />
        ) : (
          <div>
            {selectedTab === "All" &&
              !showNewPost &&
              renderCommunityPosts(currentPosts)}
            {selectedTab === "Popular" &&
              !showNewPost &&
              renderCommunityPosts(sortedCommunityByReplies)}
            {showNewPost && (
              <ProjectNewPost
                userName={userName}
                projectName={projectName}
                setCurrentPosts={setCurrentPosts}
                handleTabClick={handleTabClick}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectCommunityComponent;
