import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import DocContext from "./DocContext";
import ReactMarkdown from "react-markdown";
import Editor from "@monaco-editor/react";
import { ApiAddress } from "../../data/ApiAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWandMagicSparkles,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const SUPPORTED_CODE_EXTENSIONS = [
  "js",
  "jsx",
  "ts",
  "tsx",
  "css",
  "html",
  "py",
  "java",
  "c",
  "h",
  "cpp",
  "go",
  "rust",
  "php",
  "ino",
  "txt",
  "yml",
  "yaml",
  "json",
  "rst",
  "rs",
  "hpp",
];

const DocView = ({ userName, projectName, fileName }) => {
  const [showSummary, setShowSummary] = useState(false);
  const [summaryContent, setSummaryContent] = useState("Summarizing...");

  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const { docUrl: contextDocUrl } = React.useContext(DocContext);
  const fileExtension = fileName.split(".").pop();

  const [content, setContent] = useState("");
  const [fetchedDocUrl, setFetchedDocUrl] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");

    if (page) {
      setPageNumber(page);
    }
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // const filePath = params.get("filePath");
    const encodedFilePath = params.get("filePath");

    const decodedFilePath = atob(encodedFilePath); // Base64 decoding

    if (decodedFilePath && !fetchedDocUrl) {
      // Avoid re-fetch if we already have the doc
      fetch(ApiAddress + "get_file/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //   filePath: filePath,
          filePath: decodedFilePath,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const docUrl = URL.createObjectURL(blob);
          setFetchedDocUrl(docUrl);
        })
        .catch((error) =>
          console.error("Error fetching file in new tab:", error)
        );
    }
  }, [location.search]);

  useEffect(() => {
    if (
      ["rst", "md"].includes(fileExtension) ||
      SUPPORTED_CODE_EXTENSIONS.includes(fileExtension)
    ) {
      const urlToFetch = fetchedDocUrl || contextDocUrl; // Prefer fetched URL, if available
      fetch(urlToFetch)
        .then((response) => response.text())
        .then(setContent);
    }
  }, [fileExtension, fetchedDocUrl, contextDocUrl]);

  const docUrl = fetchedDocUrl || contextDocUrl; // Use fetched URL if available, else fallback to context

  let renderContent;

  if (!docUrl) {
    renderContent = (
      <div className="border border-dark rounded p-2">
        No documents loaded to display
      </div>
    );
  } else if (["pdf", "jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
    renderContent = (
      <iframe
        src={`${docUrl}#page=${pageNumber}`} // append the page number to the URL
        title="Document Viewer"
        className="w-full h-[100vh] mt-4"
      />
    );
  } else if (["rst", "md"].includes(fileExtension)) {
    renderContent = (
      <ReactMarkdown
        children={content}
        className="border border-dark rounded p-2"
      />
    );
  } else if (SUPPORTED_CODE_EXTENSIONS.includes(fileExtension)) {
    renderContent = (
      <Editor
        height="100vh"
        language={fileExtension}
        theme="vs-dark"
        value={content}
        options={{ readOnly: true }}
      />
    );
  } else {
    renderContent = (
      <div className="border border-dark rounded p-2">
        Cannot display folders or unsupported file types. Please download the
        file to view it in your local machine.
        <a
          href={docUrl}
          download={fileName}
          className="block w-24 mt-2 bg-emerald-500 text-white py-1 px-4 rounded hover:bg-emerald-600"
        >
          Download
        </a>
      </div>
    );
  }

  const formatText = (inputText) => {
    const elements = [];
    const lines = inputText.split("\n");

    lines.forEach((line, idx) => {
      const matches = Array.from(
        line.matchAll(/\[([^\]]+)]\((https?:\/\/[^\s]+)\)/g)
      );

      if (matches.length > 0) {
        let lastEnd = 0;
        matches.forEach((match) => {
          const start = match.index;
          const end = start + match[0].length;

          if (start > lastEnd) {
            elements.push(line.slice(lastEnd, start));
          }

          elements.push(
            <a
              key={`${idx}-${start}`}
              href={match[2]}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#10b981" }}
            >
              {match[1]}
            </a>
          );

          lastEnd = end;
        });

        if (lastEnd < line.length) {
          elements.push(line.slice(lastEnd));
        }
      } else {
        elements.push(line);
      }

      elements.push(<br key={`br-${idx}`} />);
    });

    return elements;
  };

  const handleSummaryClick = () => {
    const params = new URLSearchParams(location.search);
    // const filePath = params.get("filePath");
    const encodedFilePath = params.get("filePath");

    const decodedFilePath = atob(encodedFilePath); // Base64 decoding

    console.log("Generating summary");
    setShowSummary(true);
    // Make the api call
    fetch(ApiAddress + "get_code_summary/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
        filePath: decodedFilePath,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setSummaryContent(data.summary);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  };

  let summaryButton;

  if (SUPPORTED_CODE_EXTENSIONS.includes(fileExtension)) {
    summaryButton = (
      <button
        onClick={handleSummaryClick}
        className="text-sm flex items-center gap-2 px-2 py-1 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
      >
        <FontAwesomeIcon icon={faWandMagicSparkles} /> Detailed Summary
      </button>
    );
  }

  return (
    <div className="text-light-primary rounded-md text-sm mt-4">
      <h1 className="text-md font-bold text-emerald-500 mb-2">{fileName}</h1>
      {summaryButton}
      <div
        className={`grid mt-2 mb-2 ${showSummary ? "grid-cols-2 gap-4" : ""}`}
      >
        <div>{renderContent}</div>
        {showSummary && (
          <div className="relative border border-dark rounded p-2 mt-2 mb-2 h-[90vh]">
            <button
              onClick={() => {
                setSummaryContent("");
                setShowSummary(false);
              }}
              className="absolute top-0 right-0 hover:text-emerald-500 font-bold py-0.5 px-1.5 rounded-full"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="overflow-auto h-full font-light text-light-secondary mt-2">
              {" "}
              {formatText(summaryContent)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocView;
