import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFile,
  faCodeBranch,
  faCircleInfo,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { ApiAddress } from "../../data/ApiAddress";
import LogWindow from "../applications/LogWindow";

const UploadComponent = ({ userName, projectName, kbStatusRef }) => {
  const [githubUrl, setGithubUrl] = useState("");
  const [showGithubInput, setShowGithubInput] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleUploadFile = (event) => {
    const files_to_upload = event.target.files;

    // Create an array of fetch promises
    const fetchPromises = Array.from(files_to_upload).map((file, idx) => {
      const formData = new FormData();
      formData.append("userName", localStorage.getItem("username"));
      formData.append("projectName", projectName);
      formData.append("file", file);
      formData.append("rpath", file.name);
      console.log(formData);

      return fetch(ApiAddress + "upload_project_file/", {
        method: "POST",
        headers: {
          // Only include the Authorization header (if needed)
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          window.location.reload(); // This reloads the page
          // console.log(data);
          // On success, add the file to your files list
        });
    });

    // When all promises complete, refresh the page
    Promise.all(fetchPromises)
      .then(() => {
        // Uncomment this to make it so that the automatic creation of knowledgebase is triggered
        // kbStatusRef.current.handleCreateKnowledgebaseClick();
        window.location.reload(); // This reloads the page
      })
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );
  };
  const handleUploadFolder = (event) => {
    const files_to_upload = event.target.files;

    // Create an array of fetch promises
    const fetchPromises = Array.from(files_to_upload).map((file, idx) => {
      const formData = new FormData();
      formData.append("userName", localStorage.getItem("username"));
      formData.append("projectName", projectName);
      formData.append("file", file);
      formData.append("rpath", file.webkitRelativePath);
      // console.log(file.webkitRelativePath);

      return fetch(ApiAddress + "upload_project_file/", {
        method: "POST",
        headers: {
          // Only include the Authorization header (if needed)
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          // On success, add the file to your files list
        });
    });

    // When all promises complete, refresh the page
    Promise.all(fetchPromises)
      .then(() => {
        //kbStatusRef.current.handleCreateKnowledgebaseClick();
        window.location.reload();
      })
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );
  };

  return (
    <div className="mt-10">
      <label className="mr-2 p-2 text-sm text-light-primary rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
        <FontAwesomeIcon icon={faFolder} /> Folder Upload
        <input
          type="file"
          webkitdirectory=""
          directory=""
          onChange={handleUploadFolder}
          className="hidden"
          multiple
        />
      </label>

      <label className="mr-2 p-2 text-sm text-light-primary rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
        <FontAwesomeIcon icon={faFile} /> File Upload
        <input
          type="file"
          onChange={handleUploadFile}
          className="hidden"
          multiple
        />
      </label>

      {/* <label
        className="mr-2 p-2 text-sm text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
        onClick={() => setShowGithubInput(!showGithubInput)}
      >
        <FontAwesomeIcon icon={faCodeBranch} /> Clone GitHub Repo
      </label>

      {showGithubInput && (
        <div className="mt-4">
          <input
            type="text"
            placeholder="Enter GitHub URL"
            value={githubUrl}
            onChange={(e) => setGithubUrl(e.target.value)}
            className=" w-1/2 p-1 text-sm border rounded bg-dark-primary border border-dark rounded-lg text-white placeholder-dark focus:outline-none focus:border-emerald-600"
          />
          <button
            className="ml-2 p-1 bg-blue-500 text-white rounded hover:bg-blue-700 "
            onClick={() => {
              // handle the cloning logic here...
              console.log(`Cloning ${githubUrl}`);
            }}
          >
            Clone
          </button>
        </div>
      )} */}
      <div>
        <p className="text-dark-secondary text-xs mt-4 relative">
          <FontAwesomeIcon icon={faCircleInfo} className="text-sm mr-2" />
          See supported{" "}
          <span
            onClick={() => setShowDropdown(!showDropdown)}
            className="underline cursor-pointer text-green inline-block"
          >
            file types.
          </span>
          {showDropdown && (
            <div
              className="text-sm absolute top-full right-40 bg-dark-secondary p-4 rounded border-light shadow-lg mt-2 overflow-y-auto"
              style={{ zIndex: 1000 }}
            >
              <span
                className="cursor-pointer absolute top-2 right-2 hover:text-emerald-500"
                onClick={() => setShowDropdown(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <p>
                File types like .pdf, .pptx, .docx, .txt, .md, and code
                extensions for most programming languages are supported.
              </p>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default UploadComponent;
