import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faBook, faRobot } from "@fortawesome/free-solid-svg-icons";
import SampleQuestions from "./SampleQuestions";
import ResultCard from "./ResultCard";
import { ApiAddress } from "../../data/ApiAddress";
import FolderTreeWrapper from "./FolderTreeWrapper";
import ShowHistory from "./ShowHistory";
import HistoryFilterButtons from "./HistoryFilterButtons";
import DocContext from "../docviewer/DocContext";

const AIQnA = ({ userName, deviceName }) => {
  const [selectedSearchTypes, setSelectedSearchTypes] = useState({
    web: false, // default value for Web
    knowledgebase: true, // default value for Knowledgebase
  });
  const [result, setResult] = useState({
    question: "",
    // answer: "",
    cost: 0,
    imageurls: [],
    sources_url: [],
    scores: [],
    sources_raw: [],
    pages: [],
  });

  const [question, setQuestion] = useState("");
  const [textAreaRows, setTextAreaRows] = useState(1);
  const [showSampleQuestions, setShowSampleQuestions] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [filterDate, setFilterDate] = useState(7); // -1 for 'all', 0 for 'today, 7 for '7 days', and 30 for '30 days'
  const { globalTree, setGlobalTree } = React.useContext(DocContext);

  // Make the text area bigger if needed
  const handleTextareaChange = (e) => {
    setShowResults(false);
    setResult((prevState) => ({
      ...prevState,
      question: "",
      answer: "",
      cost: 0,
      imageurls: [],
      sources_url: [],
      scores: [],
      sources_raw: [],
      pages: [],
    }));
    setShowSampleQuestions(false);
    setQuestion(e.target.value);
    const lineCount = (e.target.value.match(/\n/g) || []).length;
    setTextAreaRows(lineCount + 1);
  };

  // handle Ask AI button click to make the API call
  const handleAskAIClick = (e) => {
    // console.log("Ask AI clicked!");
    // console.log(question);
    setResult((prevState) => ({ ...prevState, question: question }));
    setShowResults(true);

    // Make the API call and update the results here
    if (selectedSearchTypes.web) {
      fetch(ApiAddress + "web_search_agent/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: userName,
          deviceName: deviceName,
          searchQuery: question,
          searchType: "general",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);

          // On success, update the answer and the source, we need to figure out images later
          if (!data.error) {
            setResult((prevState) => ({
              ...prevState,
              answer: data.answer,
              cost: data.cost,
              sources_url: data.sources_url,
            }));
          }
        })
        .catch((error) => {
          setResult((prevState) => ({
            ...prevState,
            answer: "Server Error! Please try again!",
          }));
          console.error("Error fetching device:", error);
        });
    } else if (selectedSearchTypes.knowledgebase) {
      // console.log("Knowledgebase search");

      fetch(ApiAddress + "knowledgebase_search/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: userName,
          deviceName: deviceName,
          background: "",
          searchQuery: question,
          tree: globalTree,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);

          // On success, update the answer and the source, we need to figure out images later
          if (!data.error) {
            setResult((prevState) => ({
              ...prevState,
              answer: data.answer,
              cost: data.cost,
              sources_url: data.sources_url,
              scores: data.scores,
              sources_raw: data.sources_raw,
              pages: data.pages,
            }));
          }
        })
        .catch((error) => {
          setResult((prevState) => ({
            ...prevState,
            answer: "Server Error! Please try again!",
          }));
          console.error("Error fetching device:", error);
        });
    }

    // Reset the text area

    setQuestion("");
  };

  //   const handleCheckboxChange = (type) => {
  //     setSelectedSearchTypes((prevState) => ({
  //       ...prevState,
  //       [type]: !prevState[type],
  //     }));
  //     console.log(selectedSearchTypes);
  //   };

  const handleCheckboxChange = (type) => {
    setShowResults(false);
    setSelectedSearchTypes((prevState) => {
      if (type === "web") {
        return {
          web: !prevState.web,
          knowledgebase: prevState.web,
        };
      } else if (type === "knowledgebase") {
        return {
          web: prevState.knowledgebase,
          knowledgebase: !prevState.knowledgebase,
        };
      }
    });
  };

  const onFilterChange = (newFilterDate) => {
    setFilterDate(newFilterDate);
    // console.log(
    //   `Filter Date: ${
    //     newFilterDate === 0
    //       ? "Today"
    //       : newFilterDate === 7
    //       ? "Last 7 Days"
    //       : newFilterDate === 30
    //       ? "Last 30 Days"
    //       : "All"
    //   }`
    // );
  };

  return (
    <div className="text-light-primary rounded-md text-sm">
      <div className="flex items-center justify-between mb-2">
        <div></div>

        <div className="flex space-x-4">
          <span className="text-dark-secondary text-sm">Search type</span>
          <label className="flex items-center relative">
            <input
              type="checkbox"
              checked={selectedSearchTypes.web}
              onChange={() => handleCheckboxChange("web")}
              className="form-checkbox sr-only" // sr-only will hide it
            />
            <span
              className={`relative w-4 h-4 border rounded border-1 border-emerald-500 inline-block mr-2`}
            ></span>
            <span
              className={`absolute translate-x-1 w-2 h-2 border  border-1 border-emerald-500 inline-block mr-2 ${
                selectedSearchTypes.web ? "bg-emerald-500" : "border-0"
              }`}
            ></span>
            <FontAwesomeIcon icon={faGlobe} className="text-dark-secondary" />
            <span className="ml-2">Web</span>
          </label>
          <label className="flex items-center relative">
            <input
              type="checkbox"
              checked={selectedSearchTypes.knowledgebase}
              onChange={() => handleCheckboxChange("knowledgebase")}
              className="form-checkbox sr-only"
            />
            <span
              className={`relative w-4 h-4 border rounded border-1 border-emerald-500 inline-block mr-2`}
            ></span>
            <span
              className={`absolute translate-x-1 w-2 h-2 border  border-1 border-emerald-500 inline-block mr-2 ${
                selectedSearchTypes.knowledgebase
                  ? "bg-emerald-500"
                  : "border-0"
              }`}
            ></span>
            <FontAwesomeIcon icon={faBook} className="text-dark-secondary" />
            <span className="ml-2">Knowledgebase</span>
          </label>
        </div>
      </div>

      <div className="relative flex flex-col">
        <textarea
          rows={textAreaRows}
          value={question}
          onChange={handleTextareaChange}
          type="text"
          placeholder="Ask question to learn more about this device..."
          className="w-full pr-20 px-4 py-4 bg-dark-primary border border-dark rounded-lg text-white placeholder-dark focus:outline-none focus:border-emerald-600 resize-none"
        />
        <button
          onClick={handleAskAIClick}
          className="absolute right-2 top-2 transform translate-y-0 text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
        >
          <FontAwesomeIcon icon={faRobot} />
          <p className="text-sm">Ask AI</p>
        </button>
      </div>

      {selectedSearchTypes.knowledgebase ? (
        <div className="flex flex-row">
          <div className="w-1/4 mr-4">
            {/* Show folder tree */}
            <FolderTreeWrapper userName={userName} deviceName={deviceName} />
          </div>
          <div className="w-3/4 ">
            {/* Show sample questions */}
            {showSampleQuestions && <SampleQuestions deviceName={deviceName} />}
            <HistoryFilterButtons
              selectedFilterDate={filterDate}
              onFilterChange={onFilterChange}
            />
            {/* Show results */}
            {showResults && (
              <>
                <ResultCard
                  result={result}
                  selectedSearchTypes={selectedSearchTypes}
                  userName={userName}
                  deviceName={deviceName}
                />
              </>
            )}
            <ShowHistory
              userName={userName}
              projectName={deviceName}
              searchType="knowledgebase"
              dateFilter={filterDate}
            />
          </div>
        </div>
      ) : (
        <div>
          {/* Show sample questions */}
          {showSampleQuestions && <SampleQuestions deviceName={deviceName} />}
          <HistoryFilterButtons
            selectedFilterDate={filterDate}
            onFilterChange={onFilterChange}
          />
          {/* Show results */}
          {showResults && (
            <>
              <ResultCard
                result={result}
                selectedSearchTypes={selectedSearchTypes}
                userName={userName}
                deviceName={deviceName}
              />
            </>
          )}
          <ShowHistory
            userName={userName}
            projectName={deviceName}
            searchType="web"
            dateFilter={filterDate}
          />
        </div>
      )}
    </div>
  );
};

export default AIQnA;
