import React from "react";
import TopBar from "../components/devicespage/TopBar";
import infocard_1 from "../assets/Enterprise_Infocard_1.svg";
import infocard_2 from "../assets/Enterprise_Infocard_2.svg";
import CardComponent from "../components/information/CardComponent";
import logoUrl from "../assets/logo_new.svg";
import { Link } from "react-router-dom";
// import Login from "../components/auth/Login";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const info_card_contents = [
  {
    title: "Create Knowledgebase",
    info: "Updload documents like pdfs, word, and powerpoint as well as any codebase. ",
    image: infocard_1,
  },
  {
    title: "Leverage AI",
    info: "Utilize AI for wide variety of engineering tasks.",
    image: infocard_2,
  },
];

const EnterprisePage = () => {
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-full lg:w-4/5 mx-auto flex flex-col border border-0 mb-10">
        {/* Grid Layout */}

        <div className="flex flex-col w-full justify-center items-center p-4">
          {/* <img
            src={logoUrl}
            alt="Logo white"
            className="w-10 h-10 object-cover  mt-10"
          /> */}
          <h1 className="text-center font-bold text-xl mt-8 mb-8">
            An AI powered platform to collaborate on hardware projects
          </h1>

          <div className="flex justify-center mb-4 ">
            <Link to="/projects">
              <button className="flex justify-center text-sm items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
                Let's start <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-4 m-2">
          {info_card_contents.map((card, idx) => (
            <CardComponent key={idx} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnterprisePage;
