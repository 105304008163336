import React, { useEffect, useState } from "react";
import TopBar from "../devicespage/TopBar";
import { ApiAddress } from "../../data/ApiAddress";
import CodeGraphDisplay from "./CodeGraphDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

const CodeInspector = () => {
  const [showprojects, setShowProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(""); // New state for selected project

  const [userName, setUserName] = useState(localStorage.getItem("username"));
  const [projectName, setProjectName] = useState("");

  // Event handler for project selection change
  const handleProjectChange = (event) => {
    const newValue = event.target.value;
    setSelectedProject(newValue);
    setUserName(newValue.split("/")[0]);
    setProjectName(newValue.split("/")[1]);
    console.log(newValue);
  };

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "project_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setShowProjects(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        {/* Displays the device name and the user name and category and subcategories */}
        <h1 className="mt-10 font-bold text-lg">
          {" "}
          <FontAwesomeIcon
            icon={faCode}
            className="text-light-primary mr-2 text-red-500"
          />
          Code Inspector
        </h1>
        {/* Dropdown for project selection */}
        <div className="mt-4 flex items-center">
          <label
            htmlFor="projectSelection"
            className="mr-4 text-sm font-bold text-white"
          >
            Select project:
          </label>
          <select
            id="projectSelection"
            value={selectedProject}
            onChange={handleProjectChange}
            className="px-2 py-1 text-sm bg-dark-secondary border border-dark rounded text-emerald-500"
          >
            <option value="" disabled>
              Select a project
            </option>
            {showprojects.map((proj, index) => (
              <option key={index} value={proj.project}>
                {proj.project}
              </option>
            ))}
          </select>
        </div>
        {/* Display code graph */}

        {showprojects.length > 0 && projectName ? (
          <CodeGraphDisplay userName={userName} projectName={projectName} />
        ) : null}
      </div>
    </div>
  );
};

export default CodeInspector;
