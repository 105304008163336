import React, { useEffect, useState, useContext } from "react";
import { ApiAddress } from "../../data/ApiAddress";
import { useNavigate } from "react-router-dom";
import DocContext from "../docviewer/DocContext";

import TreeBrowser from "./TreeBrowser";
import ErrorContext from "../docviewer/ErrorContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FolderTreeWrapper = ({ userName, projectName, comment }) => {
  const setError = useContext(ErrorContext);
  const [tree, setTree] = useState({});
  const [projectTree, setProjectTree] = useState({});

  const [knowledgebaseFiles, setKnowledgebaseFiles] = useState([]);
  // const [globalTree, setGlobalTree] = useState({ checked: 0 });

  const { globalProjectTree, setGlobalProjectTree } = useContext(DocContext);

  const [isTreeChecked, setIsTreeChecked] = useState(true);
  const navigate = useNavigate();
  const { setDocUrl } = useContext(DocContext);
  const { globalTree, setGlobalTree } = useContext(DocContext);

  const onNameClick = ({ nodeData }) => {
    const fileName = nodeData.name;
    // console.log(globalTree);
    const encodedFilePath = btoa(nodeData.file_path); // Base64 encoding

    // Open the navigate URL in a new tab and initiate the fetch there
    // const newUrl = `/devices/${userName}/${deviceName}/${fileName}/docview/?filePath=${encodeURIComponent(
    //   nodeData.file_path
    // )}`;
    const newUrl = `/projects/${userName}/${projectName}/${fileName}/docview/?filePath=${encodeURIComponent(
      encodedFilePath
    )}`;

    window.open(newUrl, "_blank");
  };

  // Make API call to the back to get the folder structure
  useEffect(() => {
    const loadFolderTree = async () => {
      try {
        const response = await fetch(ApiAddress + "list_project_files/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userName: userName,
            projectName: projectName,
          }),
        });
        const data = await response.json();

        if (!data.error) {
          setProjectTree(data.tree);
          setKnowledgebaseFiles(data.knowledgebase_files);
        }
        // console.log(data);
      } catch (error) {
        console.error("Error fetching device:", error);
      }
    };
    loadFolderTree();
    // setError(
    //   "Checking files with scope the knowledgebase search to only those files!"
    // );
  }, [userName, projectName]);

  return (
    <div className="w-[1/4] h-full rounded-lg border border-dark overflow-hidden flex flex-col mt-4 mr-2">
      <h1 className="text-center bg-dark-secondary text-dark-secondary p-2 mb-2">
        Knowledgebase
      </h1>

      <div className="overflow-y-auto w-full p-0 m-2 text-dark-secondary">
        {tree !== {} && (
          <TreeBrowser
            onNameClick={onNameClick}
            tree={projectTree}
            setTree={setProjectTree}
            setGlobalTree={setGlobalProjectTree}
            setIsTreeChecked={setIsTreeChecked}
            knowledgebaseFiles={knowledgebaseFiles}
            handleDeleteClick={null}
            allowDelete={false}
          />
        )}
        {comment && (
          <p className="text-dark-secondary text-xs mt-4">
            <FontAwesomeIcon icon={faCircleInfo} className="text-sm mr-2" />
            {comment}
          </p>
        )}
      </div>
    </div>
  );
};

export default FolderTreeWrapper;
