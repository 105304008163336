import React, { useEffect, useRef } from "react";

const LogWindow = ({ content }) => {
  const endRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom to see the latest content
    if (endRef.current) {
      //   endRef.current.scrollIntoView({ behavior: "smooth" });
      endRef.current.scrollIntoView();
    }
  }, [content]);

  return (
    <div
      className="text-sm absolute top-[100%] right-40 bg-dark-secondary p-4 rounded border-dark shadow-lg mt-2 overflow-y-auto"
      style={{ height: "300px" }}
    >
      <pre className="font-mono text-light-primary text-xs p-2">
        {content}
        {/* This div is not visible but helps in scrolling to the bottom */}
        <div ref={endRef}></div>
      </pre>
    </div>
  );
};

export default LogWindow;
