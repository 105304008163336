// import React from "react";

// const ProjectCard = ({ key, project }) => {
//   return (
//     <div>
//       ProjectCard
//       {key}
//       {project.project}
//       {project.privacy_status}
//       {project.description}
//       {project.lastupdate}
//       {project.collaborators}
//     </div>
//   );
// };

// export default ProjectCard;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ApiAddress } from "../../data/ApiAddress";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  const [showTrashIcon, setShowTrashIcon] = useState(false);

  const handleProjectClick = () => {
    navigate(`/projects/${project.project}`);
  };

  const handleTrashIconClick = (e) => {
    e.stopPropagation(); // Prevent the handleProjectClick from being triggered
    const projectlink = project.project;
    const userName = projectlink.split("/")[0];
    const projectName = projectlink.split("/")[1];

    fetch(ApiAddress + "delete_project/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        projectName: projectName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (!data.error) {
          window.location.reload();
        }
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  return (
    <div
      onClick={handleProjectClick}
      onMouseEnter={() => setShowTrashIcon(true)}
      onMouseLeave={() => setShowTrashIcon(false)}
      className="bg-dark-github rounded-lg border border-dark_github overflow-hidden mb-6 flex-shrink-0 hover:shadow-[rgba(16,185,129,0.8)_0px_0px_5px]"
    >
      <div className="bg-dark-primary p-2 flex justify-between items-center">
        <div className="flex items-center">
          <span className="text-sm text-light-primary font-semibold ml-2">
            {project.project}
          </span>
          <div className="bg-dark-primary rounded-full ml-2">
            <span className="text-sm flex items-center gap-2 px-2 py-1 text-white rounded-full bg-gradient-to-br from-[#54E1AE] to-[#15BE81]">
              {project.privacy_status}
            </span>
          </div>
        </div>
        <div className="text-sm text-dark-secondary">
          Last updated on {project.lastupdate}
        </div>

        {showTrashIcon ? (
          <div>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={handleTrashIconClick}
              className="relative top-0 right-0 cursor-pointer p-2"
            />
          </div>
        ) : (
          <div>
            {" "}
            <FontAwesomeIcon
              icon={faTrash}
              className="relative top-0 right-0 cursor-pointer p-2 text-dark-secondary"
            />
          </div>
        )}
      </div>

      <div className="p-4 flex justify-between items-start">
        <div className="flex-1">
          <p className="text-dark-secondary text-sm">{project.description}</p>
        </div>
        <div className="ml-4 flex items-center text-dark-secondary text-sm">
          <FontAwesomeIcon
            icon={faUsers}
            className="ml-2 mr-2 text-light-primary"
          />
          <span className="mr-2 text-sm">
            {project.collaborators} Collaborator{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
