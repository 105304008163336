import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiAddress } from "../../data/ApiAddress";
import logoUrl from "../../assets/logo_new.svg";
import { Circles } from "react-loading-icons";
import DocContext from "../docviewer/DocContext";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(""); // for signup
  const [alert, setAlert] = useState("");
  // const [form, setForm] = useState("login");
  const { form, setForm } = useContext(DocContext);
  const navigate = useNavigate(); // Add this line
  const [temppassword, setTempPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  //   Login makes API request to the backend and fetches access token
  const loginUser = async (email, password) => {
    fetch(ApiAddress + "login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.access) {
          localStorage.setItem("token", data.access);
          localStorage.setItem("username", data.username);
          navigate("/projects"); // Add this line for redirection
        } else {
          // handle error
          setAlert(data.error);
        }
      });
  };

  const sigupUser = async (username, email, password) => {
    fetch(ApiAddress + "signup/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          loginUser(email, password); // Redirect to the main page
        } else {
          setAlert(data.error);
        }
      });
  };

  // What to do when the user clicks the "Continue" button
  const handleSubmit = async (e, url) => {
    e.preventDefault();
    // console.log("handle login");
    loginUser(email, password);
  };

  const switchSignup = () => {
    if (form === "login") {
      setForm("signup");
    } else {
      setForm("login");
    }
    setUsername("");
    setPassword("");
    setEmail("");
  };

  // Handle signup
  const handleSignup = async (e, url) => {
    e.preventDefault();
    console.log("handle signup");
    sigupUser(username, email, password);
  };

  // Show terms and conditions
  const showTermsAndConditions = () => {
    setForm("terms");
    console.log("Pop up modal to show terms and conditions");
    // setForm("terms");
  };

  //Handle Forget
  const handleForget = async (e, url) => {
    console.log("Resetting password");
    e.preventDefault();
    setLoading(true);
    if (!email) {
      setAlert("Email required!");
      return;
    }

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, email, password }),
    });

    const data = await response.json();
    setLoading(false);
    if (data.error) {
      setAlert(data.error);
    } else {
      console.log("Reset email");
      setForm("renew");
    }
  };

  //   Handle renewing password
  const handleRenew = async (e, url) => {
    console.log("Renewing password");
    e.preventDefault();

    if (!temppassword || !password) {
      setAlert("Fields missing!");
      return;
    }

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, email, password, temppassword }),
    });

    const data = await response.json();
    if (data.error) {
      setAlert(data.error);
    } else {
      console.log("Reset email complete");
      setForm("login");
    }
  };

  return (
    <div>
      {/* LOGIN PAGE */}
      {form === "login" ? (
        <div className="bg-dark-primary p-6 mx-auto rounded border border-dark shadow-xl max-w-xs w-full h-full text-light-primary mt-10">
          <img
            alt="Logo"
            src={logoUrl}
            className="h-[1.8rem] mr-4 justify-center"
          />

          <h2 className="text-2xl mb-4 text-center font-semi">Log In</h2>
          <form
            onSubmit={(e) => handleSubmit(e, ApiAddress + "login/")}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div className="mb-0">
              <label className="block text-sm font-medium">Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => setAlert("")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission
                    // Trigger the "Continue" button click event
                    const continueButton =
                      document.getElementById("continue-button");
                    if (continueButton) {
                      continueButton.click();
                    }
                  }
                }}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div className="mt-0 text-left text-sm">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setForm("forgot");
                }}
                className="text-emerald-500 hover:text-emerald-600"
              >
                Forgot password?
              </button>
            </div>

            <button
              id="continue-button"
              type="submit"
              className="w-full py-2 px-4 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
            >
              Continue
            </button>
          </form>

          {alert !== "" ? (
            <div className="mt-4 text-center">
              <p className="text-sm text-red-400">{alert}</p>
            </div>
          ) : null}

          <div className="mt-4 text-center">
            <p className="text-sm">
              Don't have an account?{" "}
              <button
                onClick={() => switchSignup()}
                className="text-emerald-500"
              >
                Sign Up
              </button>
            </p>
          </div>
        </div>
      ) : null}

      {/* SIGNUP PAGE */}
      {form === "signup" ? (
        <div className="bg-dark-primary p-6 mx-auto rounded border border-dark shadow-xl max-w-xs w-full h-full text-light-primary mt-10">
          <img
            alt="Logo"
            src={logoUrl}
            className="h-[1.8rem] mr-4 justify-center"
          />
          <h2 className="text-2xl mb-4 text-center">Sign Up</h2>
          <form
            onSubmit={(e) => handleSignup(e, ApiAddress + "signup/")}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium">Username</label>
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="mr-2 bg-emerald-500"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label className="text-xs text-light-primary">
                I agree to the{" "}
                <button
                  onClick={() => showTermsAndConditions()}
                  className="text-emerald-500 underline"
                >
                  Terms and Conditions
                </button>
                .
              </label>
            </div>
            {loading && (
              <div className="flex items-center justify-center mb-2">
                <Circles stroke="#ffffff" fill="#ffffff" height="1em" />
              </div>
            )}
            <button
              type="submit"
              disabled={!termsAccepted}
              className={`w-full py-2 px-4 ${
                termsAccepted
                  ? "bg-emerald-500 hover:bg-emerald-600"
                  : "bg-emerald-300 cursor-not-allowed"
              } text-light-primary rounded-md`}
            >
              Sign Up
            </button>
          </form>

          {alert !== "" ? (
            <div className="mt-4 text-center">
              <p className="text-red-500">{alert}</p>
            </div>
          ) : null}

          <div className="mt-4 text-center">
            <p className="text-sm">
              Already have an account?{" "}
              <button
                onClick={() => switchSignup()}
                className="text-emerald-500"
              >
                Log In
              </button>
            </p>
          </div>

          {/* <div className="mt-4 text-center">
            <p className="">
              <button
                onClick={() => showTermsAndConditions()}
                className="text-xs text-dark-secondary"
              >
                {" "}
                Terms and conditions
              </button>
            </p>
          </div> */}
        </div>
      ) : null}

      {/* FORGOT PAGE */}
      {form === "forgot" ? (
        <div className="bg-dark-primary p-6 mx-auto rounded border border-dark shadow-xl max-w-xs w-full h-full text-light-primary mt-10">
          <img
            alt="Logo"
            src={logoUrl}
            className="h-[1.8rem] mr-4 justify-center"
          />
          <h2 className="text-2xl mb-4 text-center">Reset password</h2>
          <form
            onSubmit={(e) => handleForget(e, ApiAddress + "reset_password/")}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            {loading && (
              <div className="flex items-center justify-center mb-2">
                <Circles stroke="#ffffff" fill="#ffffff" height="1em" />
              </div>
            )}
            <button
              type="submit"
              className="w-full py-2 px-4 bg-emerald-500 text-light-primary rounded-md hover:bg-emerald-600"
            >
              Continue
            </button>
          </form>

          {alert !== "" ? (
            <div className="mt-4 text-center">
              <p className="text-red-500">{alert}</p>
            </div>
          ) : null}

          <div className="mt-4 text-center">
            <p className="text-sm">
              Already have an account?{" "}
              <button
                onClick={() => switchSignup()}
                className="text-emerald-500"
              >
                Log In
              </button>
            </p>
          </div>
        </div>
      ) : null}

      {/* RENEW PASSWORD */}
      {form === "renew" ? (
        <div className="bg-dark-primary p-6 mx-auto rounded border border-dark shadow-xl max-w-xs w-full h-full text-light-primary mt-10">
          <img
            alt="Logo"
            src={logoUrl}
            className="h-[1.8rem] mr-4 justify-center"
          />
          <h2 className="text-2xl mb-4 text-center">Renew Password</h2>
          <form
            onSubmit={(e) => handleRenew(e, ApiAddress + "renew_password/")}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium">
                Temporary password
              </label>
              <input
                type="password"
                onChange={(e) => setTempPassword(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">New Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => setAlert("")}
                className="mt-1 w-full px-4 py-2 bg-dark-primary border border-dark rounded-md text-light-primary focus:outline-none focus:border-emerald-600"
              />
            </div>
            {/* {loading && (
              <div className="flex items-center justify-center mb-2">
                <Circles stroke="#26947a" fill="#000000" height="1em" />
              </div>
            )} */}
            <button
              type="submit"
              className="w-full py-2 px-4 bg-emerald-500 text-light-primary rounded-md hover:bg-emerald-600"
            >
              Reset
            </button>
          </form>

          {alert !== "" ? (
            <div className="mt-4 text-center">
              <p className="text-red-500">{alert}</p>
            </div>
          ) : null}

          <div className="mt-4 text-center">
            <p className="text-sm">
              Check your email for the temporary password.{" "}
            </p>
          </div>
        </div>
      ) : null}

      {/* TERMS AND CONDITIONS */}

      {form === "terms" ? (
        <div className="bg-dark-primary p-6 mx-auto rounded border border-dark shadow-xl max-w-xs w-full h-full text-light-primary mt-10">
          <h2 className="text-2xl mb-4 text-center">Terms and Conditions</h2>

          <div className="overflow-auto h-[60vh]">
            <h3 className="text-sm font-bold mb-4 text-center">
              Software Agreement
            </h3>

            <p className="text-xs mb-2 text-justify">
              This Software Agreement (“Agreement”) governs the disclosure of
              information by CtrlEase Corporation (“Company”) to you (the
              “Recipient”) and Recipient’s use of Company’s open-source service
              offering with user login access.
            </p>
            <p className="text-xs mb-2 text-justify">
              1. Subject to the terms and conditions of this Agreement, Company
              grants Recipient a nonexclusive, nontransferable license to use
              the Company service (“Service”) for a period designated by the
              Company for the purpose of testing and evaluating the Service.{" "}
            </p>
            {/* <p className="text-xs mb-2 text-justify">2. The Recipient agrees that it will at all times will hold in strict confidence and not disclose Confidential Information (as defined below) to any third party except as approved in writing by the Company and will use the Confidential Information for no purpose other than evaluating the Service. The Recipient shall only permit access to Confidential Information to those of its employees having a need to know and who have signed confidentiality agreements or are otherwise bound by confidentiality obligations at least as restrictive as those contained herein. “Confidential Information” means all non-public materials and information provided or made available by Company to Recipient, including products and services, information regarding technology, know-how, processes, software programs, research, development, financial information and information the Company provides regarding third parties.  </p>
                            <p className="text-xs mb-2 text-justify">3. The Recipient’s obligations under this Agreement with respect to any portion of the Confidential Information shall terminate when the Recipient can document that: (a) it was in the public domain at the time it was communicated to the Recipient; (b) it entered the public domain subsequent to the time it was communicated to the Recipient through no fault of the Recipient; (c) it was in the Recipient’s possession free of any obligation of confidence at the time it was communicated to the Recipient; (d) it was rightfully communicated to the Recipient free of any obligation of confidence subsequent to the time it was communicated to the Recipient; or (e) it was developed by employees or agents of the Recipient who had no access to any information communicated to the Recipient. After Recipient’s evaluation of the Service is complete, or upon request of the Company, the Recipient shall promptly return to the Company all documents, notes and other tangible materials and return or certify the destruction of all electronic documents, notes, software, data, and other materials in electronic form representing the Confidential Information and all copies thereof. </p> */}
            <p className="text-xs mb-2 text-justify">
              2. The Recipient agrees that nothing contained in this Agreement
              shall be construed as granting any ownership rights to any
              Confidential Information disclosed pursuant to this Agreement, or
              to any invention or any patent, copyright, trademark, or other
              intellectual property right. The Recipient shall not make, have
              made, use, or sell for any purpose any product or other item
              using, incorporating, or derived from any Confidential Information
              or the Service. The Recipient will not modify, reverse engineer,
              decompile, create other works from, or disassemble any software
              programs contained in the Confidential Information or the Service.{" "}
            </p>
            <p className="text-xs mb-2 text-justify">
              3. This Service is a beta release offering and is not at the level
              of This Service is a beta release offering and is not at the level
              of performance of a commercially available product offering. The
              Service incorporates third-party services, including tracking by
              Google. Because of the use of third-party services, this Service
              does not provide any guarantee of confidential information
              uploaded by the Recipient. The Service may not operate correctly
              and may be substantially modified prior to first commercial
              release, or at Company’s option may not be released commercially
              in the future. THE SERVICE AND DOCUMENTATION ARE PROVIDED “AS IS”
              WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS LICENSORS
              DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING
              WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR
              FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN ADVICE OR
              CONSULTATION GIVEN BY COMPANY, ITS AGENTS, OR EMPLOYEES WILL IN
              ANY WAY GIVE RISE TO A WARRANTY. THE ENTIRE RISK ARISING OUT OF
              THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH RECIPIENT.{" "}
            </p>
            <p className="text-xs mb-2 text-justify">
              4. COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF USE,
              LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS INTERRUPTION,
              OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR
              EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE SERVICE OR THIS
              AGREEMENT, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION,
              WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY,
              OR OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL COMPANY’S AGGREGATE
              CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO
              THIS AGREEMENT EXCEED $50.00 OR THE AMOUNT RECIPIENT ACTUALLY PAID
              COMPANY UNDER THIS AGREEMENT (IF ANY)..
            </p>
            {/* <p className="text-xs mb-2 text-justify">7. The Recipient’s obligations under this Agreement shall survive any termination of this agreement. This Agreement shall be governed by and construed in accordance with the laws of Delaware. The Recipient hereby agrees that breach of this Agreement will cause Company irreparable damage for which recovery of damages would be inadequate, and that the Company shall therefore be entitled to obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction.  The Recipient will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company. </p> */}
            <p className="text-xs mb-2 text-justify">
              5. By signing up for the service, the Recipient acknowledges
              understanding of the third-party tracking, including by Google,
              and has caused this Agreement to be executed on the date of the
              signup.
            </p>
          </div>
          <div className="mt-0 text-center text-sm">
            <button
              onClick={(e) => {
                e.preventDefault();
                setForm("signup");
              }}
              className="text-emerald-500 mt-4"
            >
              Go back to signup
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Login;
