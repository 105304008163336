import React, { useState, useEffect } from "react";
import imageDefault from "../../assets/image_default.png";
import { ApiAddress } from "../../data/ApiAddress";

const UserInfo = ({ userName }) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    username: "",
  });

  fetch(ApiAddress + "get_user_info/", {
    method: "POST",
    body: JSON.stringify({ userName: userName }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        // Token is probably expired. Remove local data and redirect to login.
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        // Assuming you're using React Router
        window.location.href = "/login"; // Redirect to login page
        throw new Error("Token has expired. Please login again.");
      }
      return response.json();
    })
    .then((data) => {
    //   console.log(data);
      // console.log(deviceDetail);
      // setDeviceDetail(data);
      if (!data.error) {
        setUserInfo(data);
      }
    })
    .catch((error) => console.error("Error fetching devices:", error));

  return (
    <div className="w-full flex flex-col items-center space-y-4 mt-10">
      {/* User profile picture */}
      <div className="w-4/6 bg-cover bg-center rounded-full border border-gray-300 overflow-hidden">
        <img src={imageDefault} alt="User profile" className="w-full h-full" />
      </div>

      {/* Username display */}
      <h2 className="text-xl items-start text-dark-secondary font-semibold">
        {userName}
      </h2>
      <p className="text-sm items-start text-emerald-500 font-light">
        {userInfo.email}
      </p>

      {/* Edit profile button */}
      {/* <button className="w-3/4 px-4 py-1 bg-dark-secondary text-light-primary border border-dark rounded-lg hover:bg-emerald-500">
        Edit profile
      </button> */}
    </div>
  );
};

export default UserInfo;
