import React, { useState, useEffect } from "react";
import FolderTree from "react-folder-tree";
import "./TreeBrowser.css";
import {
  ChevronDown,
  ChevronRight,
  File,
  Folder,
  FolderOpen,
  DeleteIcon,
  //   FolderOpenDot,
} from "lucide-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const TreeBrowser = ({
  onNameClick,
  tree,
  setTree,
  setGlobalTree,
  setIsTreeChecked,
  knowledgebaseFiles,
  handleDeleteClick,
  allowDelete,
  // showCheckbox,
}) => {
  const [displayedTree, setDisplayedTree] = useState(tree);
  const [isFiltered, setIsFiltered] = useState(true);

  const [showCheckbox, setShowCheckbox] = useState(false);

  // Custom Icons
  const FileIcon = () => <File className="w-4 h-4" />; // Return only desired icon here
  const FolderIcon = () => <Folder className="w-4 h-4" />; // Return only desired icon here
  const FolderOpenIcon = () => <FolderOpen className="w-4 h-4" />; // Return only desired icon here
  const EditIcon = () => null; // No need for these icons so return null
  const CancelIcon = () => <DeleteIcon className="w-4 h-4" />; // No need for these icons so return null
  const OKIcon = () => null; // No need for these icons so return null

  // Caret Right and Down need click functionality too so they're a little different
  const CaretRightIcon = ({ onClick: defaultOnClick }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <ChevronRight className="w-4 h-4" onClick={handleClick} />;
  };

  const CaretDownIcon = ({ onClick: defaultOnClick }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <ChevronDown className="w-4 h-4" onClick={handleClick} />;
  };

  // Update only globalTree on state change. Use globalTree for further processing, ignore 'tree'.
  const handleTreeStateChange = (newState) => {
    if (JSON.stringify(tree) !== JSON.stringify(newState)) {
      setGlobalTree(newState);
      if (newState["checked"] > 0) {
        setIsTreeChecked(true);
      } else {
        setIsTreeChecked(false);
      }
    }
  };

  //     | prop              | description                             | type     | options                                        |
  // |-------------------|-----------------------------------------|----------|------------------------------------------------|
  // | data              | initial tree state data (required)      | object   | N/A                                            |
  // | initCheckedStatus | initial check status of all nodes       | string   | 'unchecked' (default) \| 'checked' \| 'custom' |
  // | initOpenStatus    | initial open status of all treenodes    | string   | 'open' (default) \| 'closed' \| 'custom'        |
  // | iconComponents    | custom icon components                  | object   | ant design icons (default)                     |
  // | onChange          | callback when tree state changes        | function | console.log (default)                          |
  // | onNameClick       | callback when click treenode name       | function | open treenode inline toolbox (default)         |
  // | indentPixels      | ident pixels of 1x level treenode       | number   | 30 (default)                                   |
  // | showCheckbox      | show check box?                         | bool     | true (default) | false                         |
  // | readOnly          | readOnly mode? can't click/check node   | bool     | false (default) | true                       |

  // Helper function to filter the tree
  const filterTree = (node, validPaths) => {
    // If node is a file and is not in validPaths, filter it out
    if (!node.children && !validPaths.includes(node.file_path)) {
      return null;
    }

    // If node has children, recursively filter the children
    if (node.children) {
      const filteredChildren = node.children
        .map((child) => filterTree(child, validPaths))
        .filter(Boolean); // Remove nulls

      // Even if no children are left, return the node with an empty children array
      return {
        ...node,
        children: filteredChildren.length > 0 ? filteredChildren : [],
      };
    }

    // If node is a valid file or directory, keep it
    return node;
  };

  useEffect(() => {
    let updatedTree = tree;

    if (isFiltered) {
      updatedTree = filterTree(tree, knowledgebaseFiles);
      if (!updatedTree) {
        updatedTree = {}; // or some default structure if the root node is also filtered out
      }
    }

    setDisplayedTree(updatedTree);
    // setGlobalTree(updatedTree);
  }, [tree, knowledgebaseFiles, isFiltered]);

  const toggleFilter = () => {
    setIsFiltered((prev) => !prev);
  };

  return (
    <div>
      <div className="flex justify-start mb-2">
        <div
          onClick={() => setIsFiltered(false)}
          className={`cursor-pointer px-2 py-2 ${
            !isFiltered ? "border-b-2 border-emerald-500" : ""
          }`}
        >
          All
        </div>
        <div
          onClick={() => setIsFiltered(true)}
          className={`cursor-pointer px-2 py-2 ${
            isFiltered ? "border-b-2 border-emerald-500" : ""
          }`}
        >
          Knowledgebase
        </div>
      </div>
      <div className={isFiltered ? "knowledgebase" : ""}>
        <label>
          <input
            type="checkbox"
            onChange={(e) => setShowCheckbox(e.target.checked)}
            checked={showCheckbox}
            className="checkbox-input ml-1"
          />
        </label>

        {showCheckbox && allowDelete && (
          <button
            onClick={handleDeleteClick}
            className="border border-0 rounded bg-dark-secondary py-0 px-2 ml-2"
          >
            {/* Assuming you're using an icon library like Font Awesome, here's how you might add an icon */}
            <FontAwesomeIcon
              icon={faTrash}
              className="text-light-primary text-sm hover:text-emerald-500"
            />
            {/* <span className="ml-2"></span> */}
          </button>
        )}
        <FolderTree
          // data={tree}
          data={displayedTree}
          onChange={handleTreeStateChange}
          showCheckbox={showCheckbox}
          onNameClick={onNameClick}
          initOpenStatus="custom"
          iconComponents={{
            FileIcon,
            FolderIcon,
            FolderOpenIcon,
            EditIcon,
            CancelIcon,
            OKIcon,
            CaretDownIcon,
            CaretRightIcon,
            /* other custom icons ... */
          }}
        />
      </div>
    </div>
  );
};

export default TreeBrowser;
