import React, { useState, useEffect, useContext, useRef } from "react";
import TopBar from "../devicespage/TopBar";

import { ApiAddress } from "../../data/ApiAddress";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPlusCircle,
  faTrash,
  faCircleInfo,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import TreeBrowser from "../askai/TreeBrowser";
import DocContext from "../docviewer/DocContext";
import ProjectCommunityComponent from "./ProjectCommunityComponent";
import UploadComponent from "./UploadComponent";
import ProjectKnowledgebaseStatus from "../devicedetails/ProjectKnowledgebaseStatus";

const ProjectDetails = () => {
  // For navigation purpose
  const navigate = useNavigate();
  const kbStatusRef = useRef();

  let { userName, projectName } = useParams();
  const [showCheckbox, setShowCheckbox] = useState(false);

  const [isTreeChecked, setIsTreeChecked] = useState(false);
  const [knowledgebaseFiles, setKnowledgebaseFiles] = useState([]);

  const [projectTree, setProjectTree] = useState({});
  const { globalProjectTree, setGlobalProjectTree } = useContext(DocContext);

  const [hoveredUserName, setHoveredUserName] = useState(null);
  const [hoveredDeviceName, setHoveredDeviceName] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownDevice, setShowDropdownDevice] = useState(false);
  const [inputUsername, setInputUsername] = useState("");
  const [inputDevicename, setInputDevicename] = useState("");

  const [projectDetail, setProjectDetail] = useState({
    project: userName + "/" + projectName,
    description: "",
    collaborators: [],
    devices: [],
    // community: [],
  });

  // Handle the delete button click
  const handleDeleteClick = () => {
    console.log(globalProjectTree); // Assuming `globalProjectTree` is available in the component's scope

    fetch(ApiAddress + "project_delete_files/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        projectName: projectName,
        tree: globalProjectTree,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        kbStatusRef.current.handleCreateKnowledgebaseClick();

        // window.location.reload();
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  const handleAddCollaboratorClick = () => {
    // console.log("Adding collaborator");
    setShowDropdown(!showDropdown);
  };

  const handleDeleteCollaboratorClick = (collaborator) => {
    // console.log("Deleting...");
    // console.log(collaborator);

    //Make an API call to add the post here
    fetch(ApiAddress + "delete_collaborator/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        projectName: projectName,
        collaboratorName: collaborator,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (!data.error) {
          window.location.reload();
        }
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  const onNameClick = ({ nodeData }) => {
    console.log("On Name Click!");
    const fileName = nodeData.name;
    const filePath = nodeData.file_path;
    const encodedFilePath = btoa(nodeData.file_path); // Base64 encoding

    // console.log(fileName);
    // console.log(filePath);

    // Split the filePath by '/'
    const parts = filePath.split("/");

    // Find the index of 'backend' and get the subsequent two elements
    const backendIndex = parts.indexOf("backend");
    const userName = parts[backendIndex + 1];

    // Open the navigate URL in a new tab and initiate the fetch there
    // const newUrl = `/devices/${userName}/${deviceName}/${fileName  }/docview/?filePath=${encodeURIComponent(
    //   nodeData.file_path
    // )}`;
    const newUrl = `/projects/${userName}/${projectName}/${fileName}/docview/?filePath=${encodeURIComponent(
      encodedFilePath
    )}`;

    window.open(newUrl, "_blank");
  };

  // Fetch projectDetails form the backend
  useEffect(() => {
    fetch(ApiAddress + "project_detail/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        // userName: localStorage.getItem("username"),
        userName: userName,
        projectName: projectName,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setProjectDetail(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, [userName, projectName]);

  const handleAddUsernames = (event) => {
    if (event.key === "Enter") {
      setShowDropdown(!showDropdown);
      //Make an API call to add the post here
      fetch(ApiAddress + "add_collaborator/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          projectName: projectName,
          collaboratorName: inputUsername,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (!data.error) {
            window.location.reload();
          }
        })
        .catch((error) => console.error("Error fetching device:", error));
    }
  };

  //Make API call to the backend to get the folder structure
  useEffect(() => {
    const loadFolderTree = async () => {
      try {
        const response = await fetch(ApiAddress + "list_project_files/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userName: userName,
            projectName: projectName,
          }),
        });
        const data = await response.json();

        if (!data.error) {
          // console.log(data);
          setProjectTree(data.tree);
          setKnowledgebaseFiles(data.knowledgebase_files);
        }
        // console.log(data);
      } catch (error) {
        console.error("Error fetching device:", error);
      }
    };
    loadFolderTree();
  }, [userName, projectName]);

  // useEffect(() => {
  //   // Call the method from the ref
  //   kbStatusRef.current.handleCreateKnowledgebaseClick();
  // }, []); // Adjust dependencies as needed

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />

      <div className="w-4/5 mx-auto flex flex-col border border-0 p-2">
        {/* Left bar */}
        <div className="flex">
          <div className="flex-none w-1/2">
            {/* Displays the device name, user name, category, and subcategories */}
            <div className="mt-10 border border-0">
              <h1 className="text-light-primary font-bold mb-4">
                {projectDetail.project}
              </h1>
              <p className="text-dark-secondary font-light">
                {projectDetail.description}
              </p>
            </div>
            {/* Collaborators Section */}
            <div className="flex items-center justify-left mt-4">
              <p className="font-semibold ">Collaborators</p>
              <div className="flex items-center">
                {projectDetail.collaborators.map((collaborator, index) => (
                  <button
                    key={index}
                    onMouseEnter={() =>
                      setHoveredUserName(collaborator.username)
                    }
                    onMouseLeave={() => setHoveredUserName(null)}
                    className={`flex items-center gap-2 ml-2 px-4 py-1 rounded-full bg-dark-secondary border border-1 border-dark`}
                  >
                    <p className="text-sm text-dark-secondary">
                      {collaborator.username}
                    </p>
                    {/* Trash icon */}
                    {localStorage.getItem("username") === userName && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="flex text-sm items-center cursor-pointer text-dark-secondary pr-0 hover:text-emerald-500"
                        onClick={() =>
                          handleDeleteCollaboratorClick(hoveredUserName)
                        }
                      />
                    )}
                  </button>
                ))}
                {userName === localStorage.getItem("username") && (
                  <button
                    onClick={handleAddCollaboratorClick}
                    className="ml-2 text-dark-secondary focus:outline-none"
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="hover:text-emerald-500"
                    />
                  </button>
                )}
                {/* Input bar for adding collaborators*/}
                {showDropdown && (
                  <input
                    type="text"
                    value={inputUsername}
                    onChange={(e) => setInputUsername(e.target.value)}
                    onKeyPress={handleAddUsernames}
                    placeholder="Enter username"
                    className={`flex items-center gap-2 px-4 ml-2 py-1 rounded border border-1 border-dark text-light-primary bg-dark-primary text-sm placeholder-dark outline-none focus:border-emerald-500`}
                  />
                )}
              </div>
            </div>
            {/* Add the upload component */}
            <UploadComponent
              userName={userName}
              projectName={projectName}
              kbStatusRef={kbStatusRef}
            />
            {/* Add the knowledebase status component */}
            {/* Add tree browswer */}
            <div className="w-3/4 mt-2">
              <div className="flex justify-between">
                <div></div>
                <div>
                  <ProjectKnowledgebaseStatus
                    ref={kbStatusRef}
                    userName={userName}
                    projectName={projectName}
                  />
                </div>
              </div>
            </div>

            <div className="w-3/4 rounded-lg border border-dark overflow-hidden flex flex-col mt-4 mr-2 mb-4">
              <h1 className="text-center bg-dark-secondary text-dark-secondary p-2">
                Knowledgebase
              </h1>

              <div className="overflow-y-auto w-full min-h-[20vh] p-2 m-2 text-dark-secondary mt-4">
                {projectTree !== {} && (
                  <TreeBrowser
                    onNameClick={onNameClick}
                    tree={projectTree}
                    setTree={setProjectTree}
                    setGlobalTree={setGlobalProjectTree}
                    setIsTreeChecked={setIsTreeChecked}
                    knowledgebaseFiles={knowledgebaseFiles}
                    handleDeleteClick={handleDeleteClick}
                    allowDelete={true}
                    // showCheckbox={showCheckbox}
                  />
                )}
                <p className="text-dark-secondary text-xs mt-4">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="text-sm mr-2"
                  />
                  Please use checkboxes to select and delete files or folders.
                </p>
              </div>
            </div>
            {/* <button className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
              <FontAwesomeIcon icon={faPlus} />
              Add additional files
            </button> */}
          </div>

          <div className="flex-grow w-1/2">
            {projectDetail.community ? (
              <ProjectCommunityComponent
                community={projectDetail.community}
                userName={userName}
                projectName={projectName}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
