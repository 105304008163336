import React, { useState, useEffect } from "react";
import ResultCard from "./ResultCard";
import { ApiAddress } from "../../data/ApiAddress";

const ShowHistory = ({ userName, projectName, searchType, dateFilter }) => {
  const [historyData, setHistoryData] = useState([]);

  const [selectedSearchTypes, setSelectedSearchTypes] = useState({
    web: searchType === "web",
    knowledgebase: searchType === "knowledgebase",
  });
  useEffect(() => {
    // Make the API call to fetch history data
    fetch(ApiAddress + "project_get_qna_history/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
        searchType: searchType,
        dateFilter: dateFilter,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setHistoryData(data);
      })
      .catch((error) => console.error("Error fetching history data:", error));
  }, [userName, projectName, searchType, dateFilter]); // Dependencies for the useEffect

  return (
    <div>
      {historyData.length > 0 && (
        <>
          <h1 className="mt-4 text-lg text-dark-secondary">History</h1>
          {[...historyData].map((result, index) => (
            <ResultCard
              key={index}
              result={result}
              selectedSearchTypes={selectedSearchTypes}
              userName={userName}
              projectName={projectName}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ShowHistory;
