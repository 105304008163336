import React, { useEffect, useState } from "react";
import { ApiAddress } from "../../data/ApiAddress";
import GetIcon from "../devicespage/GetIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
// import KnowledgebaseStatus from "../devicedetails/KnowledgebaseStatus";
import ProjectKnowledgebaseStatus from "../devicedetails/ProjectKnowledgebaseStatus";

const ProjectDeviceInfoHeader = ({ userName, projectName }) => {
  // Initialize deviceDetail with default values to avoid errors when waiting for API call
  return (
    <div className="mt-10 border border-0">
      <div className="flex justify-between">
        {/* <!-- Header --> */}
        <h1 className="text-white font-bold mb-4">
          <Link to={`/projects/${userName}/${projectName}`}>
            <span className="hover:text-emerald-500">
              {userName}/{projectName}
            </span>
          </Link>
        </h1>
      </div>

      {/* <!-- Categories --> */}
    </div>
  );
};

export default ProjectDeviceInfoHeader;
