import React from "react";
import TopBar from "../components/devicespage/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCode } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const application_Info = [
  {
    title: "Knowledgebase Search",
    description: "Use AI to efficiently find information.",
    icon: faBook,
    nav_link: "search",
    color: "text-yellow-500", // added color for the icon
  },
  {
    title: "Code Inspector",
    description:
      "Generate code visualizations and code summary irrespective of the programming language.",
    icon: faCode,
    nav_link: "codeanalysis",
    color: "text-red-500", // added color for the icon
  },
];

const Applications = () => {
  const navigate = useNavigate();

  const ApplicationCard = ({ title, description, icon, nav_link, color }) => (
    <div
      className="m-5 rounded-lg shadow-md bg-dark-github p-4 border border-dark_github hover:shadow-[rgba(16,185,129,0.8)_0px_0px_5px]"
      onClick={() => navigate(`/applications/${nav_link}`)}
    >
      <h2 className="flex items-center">
        <FontAwesomeIcon icon={icon} className={`mr-2 ${color}`} />
        {title}
      </h2>
      <p className="mt-2 text-dark-secondary text-sm">{description}</p>
    </div>
  );
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />

      <div className="w-4/5 mx-auto border border-0 mt-10">
        <h1 className="text-dark-secondary ml-4">
          Please select from these lists of applications once you've create a
          project
        </h1>
        {/* flex-wrap is added to enable the two-column layout */}
        {/* Displays the device name and the user name and category and subcategories */}
        {/* Create cards for each application */}
        {application_Info.map((app, index) => (
          <ApplicationCard key={index} {...app} />
        ))}
      </div>
    </div>
  );
};

export default Applications;
