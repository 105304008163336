import React, { useState, useEffect } from "react";
import TopBar from "../components/devicespage/TopBar";
import SearchBarProject from "../components/projectspage/SearchBarProject";
import { ApiAddress } from "../data/ApiAddress";
import ProjectCard from "../components/projectspage/ProjectCard";
import LoginToEdit from "../components/auth/LoginToEdit";
import UserInfo from "../components/auth/UserInfo";

const Projects = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showprojects, setShowProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]); // Holds filtered projects based on searchTerm
  const userName = localStorage.getItem("username");

  // Filter projects whenever searchTerm or searchCategory changes
  useEffect(() => {
    // Filtering logic
    const filterAndSortProjects = () => {
      let result = [...showprojects];
      if (searchTerm) {
        result = result.filter(
          (project) =>
            project.project &&
            project.project.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      return result;
    };

    const sortedAndFiltered = filterAndSortProjects();
    setFilteredProjects(sortedAndFiltered);
  }, [searchTerm, showprojects]);

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "project_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setShowProjects(data);
        setFilteredProjects(data); // Initially, show all devices
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        {userName ? (
          <div className="flex">
            <div className="flex-none w-1/3">
              {" "}
              {/* 1/3 of the parent width */}
              <UserInfo userName={userName} />
            </div>
            <div className="flex-grow w-2/3">
              {" "}
              {/* 2/3 of the parent width */}
              <SearchBarProject
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              {filteredProjects.length === 0 ? (
                <p className="text-dark-secondary font-light p-2 mb-4">
                  A private workspace where users can collaborate on engineering
                  projects with their team. Get started by creating a new
                  project!
                </p>
              ) : (
                <p className="text-dark-secondary font-light p-2 mb-4">
                  A private workspace where users can collaborate on engineering
                  projects with their team.
                </p>
              )}
              {Array.isArray(filteredProjects) &&
                filteredProjects.length > 0 &&
                filteredProjects.map((project, index) => (
                  <ProjectCard key={index} project={project} />
                ))}
            </div>
          </div>
        ) : (
          // If userName doesn't exist
          <>
            <div className="mt-10">
              <h1 className="text-light-primary p-2 font-bold">Projects</h1>
              <p className="text-dark-secondary font-light p-2">
                A customized workspace where users can collaborate on hardware
                projects with their team
              </p>
            </div>
            <LoginToEdit comment={"create projects"} />
          </>
        )}
      </div>
    </div>
  );
};

export default Projects;
