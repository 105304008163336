import React, { useState } from "react";
// import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar } from "@fortawesome/free-solid-svg-icons";

import { Oval } from "react-loading-icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ScoreBox from "./ScoreBox";

const aggregateData = (sources_raw, pages, scores) => {
  const data = {};

  // sources_raw.forEach((source, index) => {
  //   const cleanedSource = source.replace(/\\/g, "/").split("/").pop();
  //   if (!data[cleanedSource]) {
  //     data[cleanedSource] = [];
  //   }

  //   data[cleanedSource].push({
  //     page: pages[index],
  //     score: scores[index],
  //     file_path: sources_raw[index],
  //   });
  // });
  // console.log("aggregatedData", data);

  sources_raw.forEach((source, index) => {
    const cleanedSource = source.replace(/\\/g, "/").split("/").pop();
    if (!data[cleanedSource]) {
      data[cleanedSource] = [];
    }

    const currentPage = pages[index];
    const currentScore = scores[index];
    const currentFilePath = sources_raw[index];

    const existingEntry = data[cleanedSource].find(
      (entry) => entry.page === currentPage
    );

    if (existingEntry) {
      // If an entry for the current page number exists and its score is less than the current score,
      // update the entry.
      if (existingEntry.score < currentScore) {
        existingEntry.score = currentScore;
        existingEntry.file_path = currentFilePath;
      }
    } else {
      // If no entry for the current page number exists, add the current entry.
      data[cleanedSource].push({
        page: currentPage,
        score: currentScore,
        file_path: currentFilePath,
      });
    }
  });

  return data;
};

const ResultCard = ({ result, selectedSearchTypes, userName, projectName }) => {
  const aggregatedData = aggregateData(
    result.sources_raw,
    result.pages,
    result.scores
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0); // For tracking which image is being displayed

  // console.log("Length of sources " + result.sources_raw.length);
  // console.log("selected search types " + aggregatedData);
  // console.log("Sources for History is :" + result.sources_raw);

  const handleReferenceClick = (source, item) => {
    // console.log({
    //   source,
    //   page: item.page,
    //   score: item.score,
    //   file_path: item.sources_raw,
    // });
    //
    const fileName = source;
    const encodedFilePath = btoa(item.file_path); // Base64 encoding

    // Open the navigate URL in a new tab and initiate the fetch there
    // const newUrl = `/devices/${userName}/${deviceName}/${fileName}/docview/?filePath=${encodeURIComponent(
    //   item.file_path
    // )}&page=${item.page}`;
    const newUrl = `/projects/${userName}/${projectName}/${fileName}/docview/?filePath=${encodeURIComponent(
      encodedFilePath
    )}&page=${item.page}`;

    window.open(newUrl, "_blank");
  };

  const formatText = (inputText) => {
    const elements = [];
    const lines = inputText.split("\n");

    lines.forEach((line, idx) => {
      const matches = Array.from(
        line.matchAll(/\[([^\]]+)]\((https?:\/\/[^\s]+)\)/g)
      );

      if (matches.length > 0) {
        let lastEnd = 0;
        matches.forEach((match) => {
          const start = match.index;
          const end = start + match[0].length;

          if (start > lastEnd) {
            elements.push(line.slice(lastEnd, start));
          }

          elements.push(
            <a
              key={`${idx}-${start}`}
              href={match[2]}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#10b981" }}
            >
              {match[1]}
            </a>
          );

          lastEnd = end;
        });

        if (lastEnd < line.length) {
          elements.push(line.slice(lastEnd));
        }
      } else {
        elements.push(line);
      }

      elements.push(<br key={`br-${idx}`} />);
    });

    return elements;
  };

  const formatCodeAndText = (text) => {
    if (text.indexOf("```") === -1) {
      return formatText(text);
    }

    const codeStartIndex = text.indexOf("```") + 3;
    const codeEndIndex = text.lastIndexOf("```");
    const code = text.slice(codeStartIndex, codeEndIndex);

    const textBeforeCode = formatText(text.slice(0, codeStartIndex - 3));
    const textAfterCode = formatText(text.slice(codeEndIndex + 3));

    return (
      <>
        {textBeforeCode}
        <SyntaxHighlighter
          language="cpp"
          style={vscDarkPlus}
          customStyle={{ fontSize: "8px" }}
        >
          {code}
        </SyntaxHighlighter>
        {textAfterCode}
      </>
    );
  };

  return (
    <div className="flex space-x-4 bg-dark-quaternary p-4 rounded-lg shadow-md border border-dark mt-4">
      {/* Left column */}
      <div className="w-3/4 border-r border-dark pr-2">
        <div className="flex justify-between items-center">
          <h2 className="text font-semibold text-emerald-500 mb-1">
            {result.question}
          </h2>
          {result.cost && (
            <div className="flex text-dark-secondary items-center space-x-2">
              {/* Replace this emoji with your icon */}
              <FontAwesomeIcon icon={faDollar} className="text-sm" />
              <span>{Math.round(result.cost * 1000) / 1000}</span>
            </div>
          )}
        </div>
        {result.answer === "" ? (
          <div>
            <p className="text-sm text-dark-secondary mb-1">
              > Entering new AgentExecuter chain...
            </p>
            <Oval className="h-[1rem] w-[1rem]" />
          </div>
        ) : (
          <p className="text-sm text-dark-secondary mb-1">
            After doing some search here is what I found:
          </p>
        )}
        <div className="text-sm text-light-primary break-words">
          {formatCodeAndText(result.answer)}
        </div>
      </div>

      {/* Right column */}
      <div className="w-1/4">
        {/* {result.imageurls.length > 0 && (
          <>
            <img
              src={result.imageurls[currentImageIndex]}
              alt="Source "
              className="w-full mb-2 rounded-lg shadow-sm"
            />
            <div className="flex justify-center mb-2">
              {result.imageurls.map((_, index) => (
                <span
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`inline-block w-3 h-3 rounded-full bg-dark-secondary mx-1 ${
                    currentImageIndex === index ? "bg-emerald-500" : ""
                  }`}
                ></span>
              ))}
            </div>
          </>
        )} */}

        {/* ... sources */}

        {result.sources_raw.length > 0 &&
          selectedSearchTypes.knowledgebase === true && (
            <>
              <p className="text-sm text-dark-secondary">Sources:</p>
              {Object.keys(aggregatedData).map((source, index1) => (
                <div key={source} className="mb-4 break-all">
                  <h2 className=" text-dark-secondary font-light mb-2 mt-2">
                    {source}
                  </h2>
                  {aggregatedData[source].map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-left items-center mb-1 ml-5 text-xs"
                    >
                      <div
                        className="flex items-center space-x-2 cursor-pointer"
                        onClick={() => handleReferenceClick(source, item)}
                      >
                        <span className="underline text-emerald-500">
                          Pg. {item.page}
                        </span>
                      </div>
                      {/* {item.sc */}
                      <div className="flex items-center space-x-2 ml-2">
                        <ScoreBox score={item.score} />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
      </div>
    </div>
  );
};

export default ResultCard;
