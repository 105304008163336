import React from "react";
import TopBar from "../components/devicespage/TopBar";
import { useParams } from "react-router-dom";
// import DeviceInfoHeader from "../components/askai/DeviceInfoHeader";
import DocView from "../components/docviewer/DocView";

import ProjectDeviceInfoHeader from "../components/askai/ProjectDeviceInfoHeader";

const DisplayDocumentPage = () => {
  let { userName, projectName, fileName } = useParams();
  //   console.log(userName);
  //   console.log(deviceName);

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        {/* Displays the device name and the user name and category and subcategories */}
        <ProjectDeviceInfoHeader
          userName={userName}
          projectName={projectName}
        />
        <DocView
          userName={userName}
          projectName={projectName}
          fileName={fileName}
        />
      </div>
    </div>
  );
};

export default DisplayDocumentPage;
