import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faFolderPlus,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SearchBarProject = ({ searchTerm, setSearchTerm }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // This is where you can handle the search logic if needed.
      // But since we're already updating the searchTerm state on every change,
      // this can be left empty.
    }
  };

  return (
    <div className="flex items-center justify-left p-2 mt-10 border border-0">
      {/* Label */}
      <div className="text-dark-secondary text-md font-semibold">Projects</div>

      {/* Search Bar */}
      <div className="relative flex-grow mx-4">
        <input
          type="text"
          placeholder="Search for existing projects ..."
          className="w-full px-4 py-2 bg-dark-primary border border-dark rounded-lg text-white placeholder-dark focus:outline-none focus:border-emerald-600"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {searchTerm && (
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
            onClick={() => setSearchTerm("")}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex gap-3">
        {/* dropdown button for sorting */}
        <div className="relative"></div>
        <Link to="/newproject">
          <button className="text-sm flex items-center gap-2 px-3 h-10 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
            <FontAwesomeIcon icon={faFolderPlus} />
            Create new
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SearchBarProject;
