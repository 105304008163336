import React, { useEffect, useState, useContext } from "react";
import FolderTreeWrapper from "../askai/FolderTreeWrapper";
import { ApiAddress } from "../../data/ApiAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faInfoCircle,
  faSync,
} from "@fortawesome/free-solid-svg-icons"; // Import the info icon
import DocContext from "../docviewer/DocContext";
import LogWindow from "./LogWindow";

const CodeGraphDisplay = ({ userName, projectName }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [showLogContent, setShowLogContent] = useState(false);
  const [logContent, setLogContent] = useState("");
  const { globalProjectTree, setGlobalProjectTree } = useContext(DocContext);
  const comment =
    "You can select folders and refresh display to narrow down graph view.";

  const fetchLogContent = () => {
    fetch(ApiAddress + "get_log_content/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.log_content) {
          setLogContent(data.log_content);
        }
      })
      .catch((error) => console.error("Error fetching log content:", error));
  };

  useEffect(() => {
    if (showLogContent) {
      fetchLogContent();
      const interval = setInterval(fetchLogContent, 2000);
      return () => clearInterval(interval); // Clear the interval when component unmounts or when log content is not shown
    }
  }, [showLogContent]);

  const handleInspectCode = () => {
    setIsSummarizing(true); // Set the state to summarizing

    fetch(ApiAddress + "summarize_codebase/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
        tree: globalProjectTree,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Use .text() instead of .json()
      })
      .then((data) => {
        setHtmlContent(data);
        setIsSummarizing(false); // Once completed, set state to not summarizing
      })
      .catch((error) => {
        console.error("Error fetching the graph:", error);
        setIsSummarizing(false);
      }); // Updated the error message for clarity
  };

  useEffect(() => {
    fetch(ApiAddress + "get_graph/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Use .text() instead of .json()
      })
      .then((data) => {
        // console.log(data);
        setHtmlContent(data);
      })
      .catch((error) => console.error("Error fetching the graph:", error)); // Updated the error message for clarity
  }, [userName, projectName]); // Added dependency array

  //   Update the graph based on selection
  const handleRefreshClick = () => {
    console.log("Refresh button clicked!");
    // console.log(globalProjectTree);
    fetch(ApiAddress + "update_graph/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
        tree: globalProjectTree,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Use .text() instead of .json()
      })
      .then((data) => {
        // console.log(data);
        setHtmlContent(data);
      })
      .catch((error) => console.error("Error fetching the graph:", error)); // Updated the error message for clarity
  };

  return (
    <div>
      <div className="flex flex-row">
        <div className="w-1/4 mr-4 mb-2">
          <FolderTreeWrapper
            userName={userName}
            projectName={projectName}
            comment={comment}
          />
        </div>
        {htmlContent && (
          <div className="w-3/4 mt-2">
            <div className="relative flex items-center justify-between">
              <button
                onClick={handleInspectCode}
                className={`mt-2 text-sm flex items-center gap-2 px-3 py-2 text-white rounded ${
                  isSummarizing
                    ? "bg-gradient-to-br from-orange-500 to-orange-700 hover:from-orange-600 hover:to-orange-800"
                    : "bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                }`}
              >
                <FontAwesomeIcon icon={faCode} className="text-light-primary" />
                <span className="text-sm text-light-primary">
                  Summarize codebase
                </span>
              </button>
              {isSummarizing && (
                <span className="ml-4 mt-2 text-xs text-dark-secondary font-light ">
                  Summarizing code in progress...{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setShowLogContent(!showLogContent)}
                  >
                    See status
                  </span>
                </span>
              )}
              {showLogContent && isSummarizing && (
                <LogWindow content={logContent} />
              )}

              {/* Added Refresh Button Below */}
              <button
                onClick={handleRefreshClick}
                className="mt-2 text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
              >
                <FontAwesomeIcon icon={faSync} className="text-light-primary" />
                <span className="text-sm text-light-primary">Refresh</span>
              </button>
            </div>

            <div>
              <p className="text-xs text-dark-secondary font-light mt-2">
                Please hover over each node in the graph to inspect code. Gray
                nodes are not part of the knowledebase and the AI tool doesn't
                inspect these nodes.
              </p>
            </div>

            <iframe
              className="rounded mt-4 w-full h-[80vh] bg-dark-github"
              srcDoc={htmlContent}
              title="Graph"
              //   width="100%"
              //   height="600px" // Adjust the height as necessary
              frameBorder="0"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeGraphDisplay;
