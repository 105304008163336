import React from "react";
import { Link } from "react-router-dom";

const CardComponent = ({ title, info, image }) => {
  return (
    <div className="rounded-lg shadow-md bg-dark-github p-4 border border-dark_github hover:shadow-[rgba(16,185,129,0.8)_0px_0px_5px]">
      <div className="mt-4">
        <h2 className="font-semibold text-md mb-2 ml-4 text-emerald-500">
          {title}
        </h2>
        <p className="font-light text-sm ml-4">{info}</p>
      </div>
      <div className="flex justify-center">
        {image && (
          <img
            src={image}
            alt={title}
            className="w-3/4 object-cover rounded-t-lg mt-4 mx-auto"
          />
        )}
      </div>
    </div>
  );
};

export default CardComponent;
