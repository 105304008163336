//App.js
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React, { useState } from "react";
import Devices from "./pages/Devices";
import Projects from "./pages/Projects";
import DeviceDetails from "./pages/DeviceDetails";
import AuthPage from "./pages/AuthPage";
import AskAIPage from "./pages/AskAIPage";
import NewDevice from "./pages/NewDevice";
import DisplayDocumentPage from "./pages/DisplayDocumentPage";

import DocContext from "./components/docviewer/DocContext";
import NewProject from "./pages/NewProject";
import ProjectDetails from "./components/projectspage/ProjectDetails";
import ProjectAskAIPage from "./pages/ProjectAskAIPage";
import MainPage from "./pages/MainPage";
import ReactGA from "react-ga";
import EnterprisePage from "./pages/EnterprisePage";
import Applications from "./pages/Applications";

import ErrorContext from "./components/docviewer/ErrorContext";
import ProjectsSignup from "./pages/ProjectsSignup";
import KnowledgebaseSearch from "./components/applications/KnowledgebaseSearch";
import CodeAnalysis from "./components/applications/CodeInspector";

const TRACKING_ID = "UA-286067360-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [docUrl, setDocUrl] = useState(null);
  const [globalTree, setGlobalTree] = useState({ checked: 0 });
  const [globalProjectTree, setGlobalProjectTree] = useState({ checked: 0 });
  const [error, setError] = useState(null);
  const [form, setForm] = useState("login");

  const setErrorAndTimeout = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(null), 4000); // Error disappears after 10 seconds
  };

  return (
    <BrowserRouter>
      {error && (
        <div className="fixed inset-x-0 bottom-0 p-4 z-10">
          <div className="mx-auto max-w-screen-lg bg-red-600 bg-opacity-90 text-white p-4 rounded shadow-2xl">
            {error}
          </div>
        </div>
      )}
      <DocContext.Provider
        value={{
          docUrl,
          setDocUrl,
          globalTree,
          setGlobalTree,
          globalProjectTree,
          setGlobalProjectTree,
          form,
          setForm,
        }}
      >
        <ErrorContext.Provider value={setErrorAndTimeout}>
          <Routes>
            <Route path="/" element={<EnterprisePage />} />
            <Route path="/login" element={<AuthPage />} />
            {/* <Route path="/devices" element={<Devices />} /> */}
            <Route path="/applications" element={<Applications />} />
            <Route
              path="/applications/search"
              element={<KnowledgebaseSearch />}
            />
            <Route
              path="/applications/codeanalysis"
              element={<CodeAnalysis />}
            />
            <Route
              path="/devices/:userName/:deviceName"
              element={<DeviceDetails />}
            />
            <Route
              path="/projects/:userName/:projectName"
              element={<ProjectDetails />}
            />
            <Route
              path="/devices/:userName/:deviceName/askai/"
              element={<AskAIPage />}
            />
            {/* <Route
              path="/projects/:userName/:projectName/askai/"
              element={<ProjectAskAIPage />}
            /> */}
            {/* <Route
              path="/devices/:userName/:deviceName/:fileName/docview/"
              element={<DisplayDocumentPage />}
            /> */}

            <Route
              path="/projects/:userName/:projectName/:fileName/docview/"
              element={<DisplayDocumentPage />}
            />
            <Route path="/newdevice" element={<NewDevice />} />
            <Route path="/newproject" element={<NewProject />} />
            <Route path="/projects" element={<Projects />} />

            {/* <Route path="/projects" element={<ProjectsSignup />} /> */}
            {/* <Route path="*" element={<Navigate to="/devices" replace />} /> */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ErrorContext.Provider>
      </DocContext.Provider>
    </BrowserRouter>
  );
}

export default App;
